// modified version (without :focus) 
.tb-form-control-focus(@color: @input-border-focus) {
  @color-rgba: rgba(red(@color), green(@color), blue(@color), .6);
  & {
    border-color: @color;
    outline: 0;
    .box-shadow(~"inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px @{color-rgba}");
  }
}

.hard-focus() {
    border : 2px solid @input-border-focus;
    border-radius : 4px;
}
