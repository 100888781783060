.brainTreePaymentMethod() {
    & {
        width  : 44px !important;
        right  : 50px !important;
        background-image : url("../img2/paymentmethods.png");
        background-repeat : no-repeat;

        &.none {
            background-position : 0 100px;
        }

        &.visa {
            background-position : 0 -231px;
        }

        &.american-express {
            background-position : 0 -119px;
        }

        &.discover {
            background-position : 0 -287px;
        }

        &.diners-club {
            background-position : 0 -259px;
        }

        &.master-card {
            background-position : 0 -203px;
        }
    }
}
