@import "bootstrap/variables";

body {
    margin-top : 20px;
}

.content-wrapper > .container {
    @media (min-width : @screen-lg-min) {
        width : 900px;
    }
}

.header {
    margin-top : 6px;

    font-family: 'Oswald', sans-serif;

    @media (max-width : @screen-sm-max) {
        margin-top : 0;
        text-align : center;
    }
    
    h1 {
        font-size : 28px;
        margin : 0;
    }

    .questions {
        color : #777;
        
        @media (min-width : @screen-lg-min) {
            margin-top : 4px;
            text-align : right;
        }
    }
}

footer {
    margin-top : 40px;
    padding-bottom : 40px;

    @media (min-width : @screen-lg-min) {
        & > .container {
            width : 900px;
        }
    }
}

.footer-copyright {
    text-align:right;
    color:#6c7e8f;

    padding: 4px 0;
    font-size: 13px;
}
