@import "tbfont-cachebuster";
@import "tbfont-glyphmap";

@font-face {
	font-family:'tbfont';
	src:url('../fonts/tbfont.eot?@{tbfonttimestamp}');
	src:url('../fonts/tbfont.eot?@{tbfonttimestamp}#iefix') format('embedded-opentype'),
		url('../fonts/tbfont.woff?@{tbfonttimestamp}') format('woff'),
		url('../fonts/tbfont.ttf?@{tbfonttimestamp}') format('truetype'),
        url('../fonts/tbfont.svg?@{tbfonttimestamp}#tbfont') format('svg');
	font-weight:normal;
	font-style:normal;
}

.tbicon() {
    font-family: 'tbfont';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height : 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="tbicon"],
[class*="tbicon"] {
    .tbicon();
}

.createglyphmap(@iterator:1) when(@iterator <= length(@tbfontglyphmap)) {
    @name: extract(extract(@tbfontglyphmap, @iterator),1);
    
    &@{name}:before {
        content: extract(extract(@tbfontglyphmap, @iterator),2);
    }
    .createglyphmap((@iterator + 1));
}
.tbicon-{
    .createglyphmap();
}
