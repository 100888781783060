.plans-table, .settings-table {
    width : 100%;

    @media print {
        & {
            border-bottom : 1px solid black !important;
            border-top : 1px solid black !important;
            border-left : 1px solid black !important;
            border-right : 1px solid black !important;
        }
        
        td, th {
            border-top-width : 1px !important;
            border-left-width : 1px !important;
            border-right-width : 1px !important;
            border-bottom-width : 1px !important;
            border-color : black !important;
        }
    }

    td, th {
        border-bottom : 5px solid white;
    }
    
    td:last-child, th:last-child {
        border-left : 5px solid white;
    }
    
    th {
        margin:0;
        font-size:24px;
        text-align:center;
        padding-top : 10px;
        padding-bottom : 10px;
        font-family: 'Oswald', sans-serif;
        font-weight:400;
        color:white;

        &.col1 {
            background-color : @brand-success;
        }

        &.col2 {
            background-color : @brand-primary;
        }
    }
    
    td {
        margin:0;
        font-size:18px;
        height:60px;
        text-align:center;
        line-height:24px;
        font-family: 'Lato', sans-serif;
        font-weight:normal;
        color:black;

        &.col1 {
            background-color : @brand-success-light;
        }

        &.col2 {
            background-color : @brand-primary-light;
        }
    }

    strong {
        font-weight:bolder;
        font-size:larger;
    }

    a.btn-block {
        padding-top : 9px;
        padding-bottom : 9px;
    }
}

.settings-table {
    th {
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 18px;
        text-align: left;

        &:first-child {
            background-color : @brand-success;
        }

        &:last-child {
            background-color : @brand-primary;
        }
    }

    td {
        height: 40px;
        padding-left:18px;
        font-family: 'Lato', sans-serif;
        font-size: 16px;
        text-align: left;

        &:first-child {
            background-color : @brand-success-light;
        }

        &:last-child {
            background-color : @brand-primary-light;
        }
    }
}
