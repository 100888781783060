.hidden-initial {
    display : none;
}

.gutter-0() {
    .row {
        margin-right : 0;
        margin-left  : 0;
    }
    
    [class*="col-"] {
        padding-right : 0;
        padding-left  : 0;
    }
}

.gutter-4() {
    .row {
        margin-right : -2px;
        margin-left  : -2px;
    }
    
    [class*="col-"] {
        padding-right : 2px;
        padding-left  : 2px;
    }
}

.gutter-8() {
    .row {
        margin-right : -4px;
        margin-left  : -4px;
    }
    
    [class*="col-"] {
        padding-right : 4px;
        padding-left  : 4px;
    }
}
