.modal-alert {
    &.modal-contains-alert {
        img {
            margin-top : 10px;
        }
        
        p {
            .bold-magra();
        }
    }
    
    &.modal-https-only {
        p {
            .bold-magra();
            margin : 10px 0 10px 50px;
        }
    }

    &.modal-loading {
        p {
            .bold-magra();
            margin : 10px 0 10px 42px;
        }
        img {
            padding-right : 15px;
            margin-bottom : 3px;
        }
    }

    .modal-dialog {
        margin-top : 10%;
    }

    button.close {
        position : absolute;
        top : 4px;
        right : 10px;
    }
}
