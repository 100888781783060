h1, h2, h3 {
    margin-top : 30px;
    margin-bottom : 15px;
}

h1 {
    font-family: 'Oswald', sans-serif;
    font-size: 36px;
    font-weight: 400;
    font-style: normal;
    color: @brand-primary;
}

h2 {
    .bold-magra();
}

p {
    margin-bottom : 15px;
}

ul, ol {
    margin-bottom : 15px;
}

hr {
    border-top:1px solid #ccc;
    border-bottom:none;
    margin-top:20px;
}

.magra {
    font-family:'Magra','Lato',sans-serif;
    font-weight:700;
}

.bold-magra {
    font-family:'Magra','Lato',sans-serif;
    font-weight:700;
    color:#34495E;
    font-size:24px;
}

.lato {
    font-family:'Lato',Tahoma,Geneva,sans-serif;
}
.lato-link a {
    font-family:'Lato',Tahoma,Geneva,sans-serif;
    color:#989898;
}
.magra-strong {
    font-weight:bold;
    color:@brand-primary;
    font-family:'Magra','Lato',sans-serif;
}
.gray-italic {
    color:#505050;
    font-style:italic;
}
.text-green {
    color: @green-link-color;
}
