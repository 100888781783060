@import "functions/kvp"; // used to lookup tbfont glyph encodings

select {
    padding-left : 8px !important;
}

.styled-select {
    position : relative;
    
    button {
        text-align : left;
        z-index : 1;
        padding-right : 28px;

        .val {
            overflow-x : hidden;
            white-space : nowrap;
        }

        .caret {
            position : absolute;
            top : 10px;
            right : 11px;
        }
    }

    select {
        position : absolute;
        top : 0;
        left : 0;
        opacity : 0;
        z-index : 2;

        &:focus + button {
            .tb-form-control-focus();
        }
    }
}

.form-control {
    &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px white inset;
        -webkit-text-fill-color : #555;
    }
}

// customized checkbox
input[type="checkbox"] {
    width : 0;
    outline : none !important;

    &:focus + .label-checkbox {
        .hard-focus();
    }
    
    & + .label-checkbox {
        border : 2px solid transparent;
        
        &:before {
            color : tint(@wet-asphalt, 70%);
            font-weight : normal;
            .kvp(@tbfontglyphmap, checkbox-unchecked);
            content : @kvp;
            font-family : tbfont;
        }
    }

    &:checked {
        + .label-checkbox {
            &:before {
                color : @wet-asphalt;
                .kvp(@tbfontglyphmap, checkbox-checked);
                content : @kvp;
            }
        }
    }
}

// for ie8
label.label-checkbox.checked {
    &:before {
        color : @wet-asphalt;
        .kvp(@tbfontglyphmap, checkbox-checked);
        content : @kvp !important;
    }
}

// braintree hosted fields

.form-control.braintree-hosted-fields {
    // add focus style to braintree hosted fields
    
    &.braintree-hosted-fields-focused {
        .tb-form-control-focus();
    }

    // fix text positioning
    padding-top : 3px;
    padding-bottom : 1px;
}

.form-section {
    margin-top : 20px;
}

.form-control-feedback {
    padding-right : 8px;
    top : 35px !important;
    
    &.required {
        padding-top : 6px;
        font-weight : bold;
        font-size : 30px;
        color : grey;
    }
}

.form-horizontal {
    .form-group {
        margin-bottom : 0;
    }
    
    label {
        text-align : right;
        
        &.section {
            .bold-magra();

            & + div {
                margin-top : 10px;
            }
        }
    }

    .form-control-feedback {
        top : 0 !important;
    }
}
