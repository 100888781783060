.btn {
    -webkit-font-smoothing: subpixel-antialiased;
    transition: border .25s linear, color .25s linear, background-color .25s linear;

    &:focus,
    &:hover {
        &:not([disabled]) {
            .tb-form-control-focus();
        }
    }
}

.content-wrapper {
    a:not(.btn):not(.a-pager) {
        border : 2px solid transparent;
        
        &:focus, &:hover {
            .hard-focus();
        }
    }
}

