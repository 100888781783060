@local-font-path:               "../fonts/lato/";
@local-font-name:               "lato-regular";
@local-font-svg-id:             "latoregular";
@local-font-name-light:         "lato-light";
@local-font-svg-id-light:       "latolight";
@local-font-name-black:         "lato-black";
@local-font-svg-id-black:       "latoblack";
@local-font-name-bold:          "lato-bold";
@local-font-svg-id-bold:        "latobold";
@local-font-name-italic:        "lato-italic";
@local-font-svg-id-italic:      "latoitalic";
@local-font-name-bold-italic:   "lato-bolditalic";
@local-font-svg-id-bold-italic: "latobold-italic";

@font-face {
  font-family: 'Lato';
  src: url('@{local-font-path}@{local-font-name-black}.eot');
  src: url('@{local-font-path}@{local-font-name-black}.eot?#iefix') format('embedded-opentype'),
       url('@{local-font-path}@{local-font-name-black}.woff') format('woff'),
       url('@{local-font-path}@{local-font-name-black}.ttf') format('truetype'),
       url('@{local-font-path}@{local-font-name-black}.svg#@{local-font-svg-id-black}') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('@{local-font-path}@{local-font-name-bold}.eot');
  src: url('@{local-font-path}@{local-font-name-bold}.eot?#iefix') format('embedded-opentype'),
       url('@{local-font-path}@{local-font-name-bold}.woff') format('woff'),
       url('@{local-font-path}@{local-font-name-bold}.ttf') format('truetype'),
       url('@{local-font-path}@{local-font-name-bold}.svg#@{local-font-svg-id-bold}') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('@{local-font-path}@{local-font-name-bold-italic}.eot');
  src: url('@{local-font-path}@{local-font-name-bold-italic}.eot?#iefix') format('embedded-opentype'),
       url('@{local-font-path}@{local-font-name-bold-italic}.woff') format('woff'),
       url('@{local-font-path}@{local-font-name-bold-italic}.ttf') format('truetype'),
       url('@{local-font-path}@{local-font-name-bold-italic}.svg#@{local-font-svg-id-bold-italic}') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('@{local-font-path}@{local-font-name-italic}.eot');
  src: url('@{local-font-path}@{local-font-name-italic}.eot?#iefix') format('embedded-opentype'),
       url('@{local-font-path}@{local-font-name-italic}.woff') format('woff'),
       url('@{local-font-path}@{local-font-name-italic}.ttf') format('truetype'),
       url('@{local-font-path}@{local-font-name-italic}.svg#@{local-font-svg-id-italic}') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('@{local-font-path}@{local-font-name-light}.eot');
  src: url('@{local-font-path}@{local-font-name-light}.eot?#iefix') format('embedded-opentype'),
       url('@{local-font-path}@{local-font-name-light}.woff') format('woff'),
       url('@{local-font-path}@{local-font-name-light}.ttf') format('truetype'),
       url('@{local-font-path}@{local-font-name-light}.svg#@{local-font-svg-id-light}') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('@{local-font-path}@{local-font-name}.eot');
  src: url('@{local-font-path}@{local-font-name}.eot?#iefix') format('embedded-opentype'),
       url('@{local-font-path}@{local-font-name}.woff') format('woff'),
       url('@{local-font-path}@{local-font-name}.ttf') format('truetype'),
       url('@{local-font-path}@{local-font-name}.svg#@{local-font-svg-id}') format('svg');
  font-weight: normal;
  font-style: normal;
}

// oswald-300 - latin
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/oswald/oswald-v10-latin-300.eot'); // IE9 Compat Modes
    src: local('Oswald Light'), local('Oswald-Light'),
    url('../fonts/oswald/oswald-v10-latin-300.eot?#iefix') format('embedded-opentype'), // IE6-IE8
    url('../fonts/oswald/oswald-v10-latin-300.woff2') format('woff2'), // Super Modern Browsers
    url('../fonts/oswald/oswald-v10-latin-300.woff') format('woff'), // Modern Browsers
    url('../fonts/oswald/oswald-v10-latin-300.ttf') format('truetype'), // Safari, Android, iOS
    url('../fonts/oswald/oswald-v10-latin-300.svg#Oswald') format('svg'); // Legacy iOS
}
// oswald-regular - latin
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/oswald/oswald-v10-latin-regular.eot'); // IE9 Compat Modes
    src: local('Oswald Regular'), local('Oswald-Regular'),
    url('../fonts/oswald/oswald-v10-latin-regular.eot?#iefix') format('embedded-opentype'), // IE6-IE8
    url('../fonts/oswald/oswald-v10-latin-regular.woff2') format('woff2'), // Super Modern Browsers
    url('../fonts/oswald/oswald-v10-latin-regular.woff') format('woff'), // Modern Browsers
    url('../fonts/oswald/oswald-v10-latin-regular.ttf') format('truetype'), // Safari, Android, iOS
    url('../fonts/oswald/oswald-v10-latin-regular.svg#Oswald') format('svg'); // Legacy iOS
}
// oswald-700 - latin
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/oswald/oswald-v10-latin-700.eot'); // IE9 Compat Modes
    src: local('Oswald Bold'), local('Oswald-Bold'),
    url('../fonts/oswald/oswald-v10-latin-700.eot?#iefix') format('embedded-opentype'), // IE6-IE8
    url('../fonts/oswald/oswald-v10-latin-700.woff2') format('woff2'), // Super Modern Browsers
    url('../fonts/oswald/oswald-v10-latin-700.woff') format('woff'), // Modern Browsers
    url('../fonts/oswald/oswald-v10-latin-700.ttf') format('truetype'), // Safari, Android, iOS
    url('../fonts/oswald/oswald-v10-latin-700.svg#Oswald') format('svg'); // Legacy iOS
}
// sanchez-regular - latin
@font-face {
    font-family: 'Sanchez';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/sanchez/sanchez-v4-latin-regular.eot'); // IE9 Compat Modes
    src: local('Sanchez'), local('Sanchez-Regular'),
    url('../fonts/sanchez/sanchez-v4-latin-regular.eot?#iefix') format('embedded-opentype'), // IE6-IE8
    url('../fonts/sanchez/sanchez-v4-latin-regular.woff2') format('woff2'), // Super Modern Browsers
    url('../fonts/sanchez/sanchez-v4-latin-regular.woff') format('woff'), // Modern Browsers
    url('../fonts/sanchez/sanchez-v4-latin-regular.ttf') format('truetype'), // Safari, Android, iOS
    url('../fonts/sanchez/sanchez-v4-latin-regular.svg#Sanchez') format('svg'); // Legacy iOS
}
// magra-700 - latin
@font-face {
    font-family: 'Magra';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/magra/magra-v4-latin-700.eot'); // IE9 Compat Modes
    src: local('Magra Bold'), local('Magra-Bold'),
    url('../fonts/magra/magra-v4-latin-700.eot?#iefix') format('embedded-opentype'), // IE6-IE8
    url('../fonts/magra/magra-v4-latin-700.woff2') format('woff2'), // Super Modern Browsers
    url('../fonts/magra/magra-v4-latin-700.woff') format('woff'), // Modern Browsers
    url('../fonts/magra/magra-v4-latin-700.ttf') format('truetype'), // Safari, Android, iOS
    url('../fonts/magra/magra-v4-latin-700.svg#Magra') format('svg'); // Legacy iOS
}

.sanchez {
    font-family:'Sanchez',serif;
}