.login-container {
    &.login-page {
        margin-top : 30px;
    }

    .login-status {
        min-height : 40px;
        margin-bottom : 10px;

        .processing {
            .hidden-initial();
            
            h2 {
                margin : 0;

                img {
                    margin-bottom : 3px;
                }
            }
        }

        .alert {
            padding : 3px 15px;
            margin : 0;
        }
    }
}
