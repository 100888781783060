.signup-form {
    .customNumber-available label {
        text-align : center;
    }
    
    .subpage-confirmation {
        table {
            //width : 100%;

            tr {
                &.sectionLabel {
                    .bold-magra();
                }
                
                &.confirmField td {
                    background-color : @brand-success-light;
                }

                td {
                    border-bottom : 5px solid white;
                }
                
                td.value {
                    border-left : 5px solid white;
                }

                &.fg-totalAmount, &.fg-totalAmountWithCustomNumber {
                    font-weight : bold;
                }
            }
        }
    }

    .subpage-complete {
        margin-top : 20px;

        table td {
            padding-right : 20px;
        }
    }
    
    .btn-continue-text {
        font-size : 14px;
        padding-left : 10px;
    }

    .paymentMethod {
        .brainTreePaymentMethod();
    }

    .plans-table {
        th, td {
            font-size  : 18px;
            border-right : 5px solid white;
            padding-top : 6px;
            padding-bottom : 6px;
            height : auto;
        }
        
        .td-customNumber {
            height : 49px;
        }
        
        th {
            background-color : @brand-success;
        }
        
        td, label {
            background-color : @brand-success-light;
            font-weight : bold;
        }

        .noBorder {
            border : 0;
        }

        td:last-child, th:last-child {
            border-right : 0;
        }
    }

    .modal-login {
        .modal-body {
            padding : 0;

            .panel-primary {
                margin : 0;
                border : 0;

                border-radius : 6px;

                .panel-heading {
                    border-radius : 0;
                }
            }
        }
    }
}
